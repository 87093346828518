@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Nunito';
  }
}

@layer utilities {
  .direction-rtl {
    direction: rtl;
  }

  .direction-ltr {
    direction: ltr;
  }
}

* {
  font-family: 'Nunito';
}

.react-multiple-carousel__arrow--right {
  right: 8px !important;
}

.react-multiple-carousel__arrow--left {
  left: 8px !important;
}

.react-multiple-carousel__arrow::before {
  color: #4282F3 !important;
  font-size: 30px !important;
  font-weight: 900 !important;
}

.react-multiple-carousel__arrow {
  background: transparent !important;
}

details > summary.list-none::-webkit-details-marker,
details > summary.list-none::marker {
  display: none;
}

.no-highlights {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

